import React from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle } from "styled-components"
import "../fonts/fonts.css"
import Helmet from "react-helmet"

const GlobalStyle = createGlobalStyle`

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #000;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
}

main {
  min-height: 100vh;
  max-height: 100%;
  width: 100vw;
}

a {
  text-decoration: none;
  font-family: "Velo Sans Text", sans-serif;
}

p {
  font-family: "benton-sans", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 200;
  line-height: 1.447em;
  margin: 3px;
  padding: 10px 0;
}

p a {
  text-decoration: none;
  color: #45d8c3;
  font-weight: 300;
}

:visited p a {
  color: #fffaf8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Velo Sans Text";
  font-style: italic;
  font-weight: 200;
}

source {
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 1000px) {
  html {
    background: #000;
  }

  body {
    background: #000;
  }
}
`
const LayoutDiv = styled.div`
  width: 100vw;
  min-height: 100vh;
  max-height: 100%;
`

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <style>@import url("https://use.typekit.net/ksy1znm.css");</style>
      </Helmet>
      <GlobalStyle />
      <LayoutDiv>
        <main>{children}</main>
      </LayoutDiv>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
